import { urlParams, APP_URL, TEAMS_USER_IMPRESSION_SERVICE_ID } from "./../constants/AppConstants";
import { app } from "@microsoft/teams-js";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}
export let TeamsController = {
  service: "Teams",
  landingPage: true,
  landingPagePathname: "/teams/landing",
  sideBarExtend: {
    hideAutomationSideBar: true,
    hideVoiceSideBar: true,
  },

  init: function () {
    return new Promise((resolve, reject) => {
      if (window.parent !== window) {
        // Only initialize if inside an iframe (like in Teams)
        app
          .initialize()
          .then(() => {
            console.log("Teams SDK initialized");
            app.notifySuccess();
            resolve();
          })
          .catch((error) => {
            console.error("Teams SDK initialization failed:", error);
          });
      } else {
        console.warn("App is running outside of Microsoft Teams");
        resolve();
      }
    });
  },

  isCreditAccessible : function () {
    return new Promise((resolve, reject) => {
      if(app && window.parent !== window){
        app.getContext().then((context) => {
          console.log("context>>>>", context);
          console.log("context.app.host.clientType>>>>", context.app.host.clientType);
          if (["desktop","web"].includes(context.app.host.clientType)) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  },

  getCustomAuthUrl: function (appProperties) {
    return new Promise((resolve, reject) => {
      const redirectUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=${
        appProperties?.leftServiceClientDetails?.clientId
      }&redirect_uri=https://api.oapps.xyz/rest/api/v2/redirect&state=${
        appProperties?.osyncId
      }::${TEAMS_USER_IMPRESSION_SERVICE_ID}::${TEAMS_USER_IMPRESSION_SERVICE_ID}::true&scope=${encodeURIComponent(
        "https://api.interfaces.records.teams.microsoft.com/user_impersonation offline_access"
      )}&access_type=offline&prompt=select_account`;
      resolve(redirectUrl);
    });
  },

  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var leftServiceName = urlParams.get("service");
      var allUrlParams = getAllUrlParams();
      var redirectSendSMSFromModule = domain + "/persons/list";
      console.log("allUrlParams>>>>", allUrlParams);
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&leftServiceName=${leftServiceName}&urlParams=${allUrlParams}&redirectSendSMSFromModule=${redirectSendSMSFromModule}`;

      console.log("hrefUrl >>>>>>>>>>>>>>>>>>>.", hrefUrl);
      resolve(hrefUrl);
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      const keysToAdd = ["emailNotification", "defaultCountryCode", "inboxAccessSettings"];
      const keysToRemove = ["recordOwner"];

      const filterSettings = (obj) => {
        if (Array.isArray(obj)) {
          return obj
            .filter((item) => item && (keysToAdd.includes(item.key) || keysToAdd.includes(item.parentKey)))
            .map((item) => {
              if (item.additionalFields) {
                item.additionalFields = item.additionalFields.filter((field) => !keysToRemove.includes(field.key));
              }
              return item;
            });
        }
        return [];
      };

      const filteredArray = filterSettings(settingsObj);
      resolve(filteredArray);
    });
  },

  defaultSettingValue: {
    emailNotification: {
      sendEmail: true,
      accountOwner: true,
    },
    userNotification: "true",
  },
  getAssociatedObjectType: function () {
    return new Promise((resolve, reject) => {
      resolve("Contacts");
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    return new Promise((resolve, reject) => {
      var redirectUrl = `leftServiceId=${appProperties.leftServiceId || urlParams.get("leftServiceId")}&service=teams&domain=org${
        appProperties.companyId
      }&userId=${appProperties.userId}&companyId=${appProperties.companyId}`;
      resolve(redirectUrl);
    });
  },
};
