import { failureNotification } from "../pages/utils/CommonNotifications";
import { httpsUrlRegex } from "../pages/utils/commonUtils";
import useHttp from "./useHttp";

function useAttachment(appProperties, fileList, setFileList, alertNotNeeded) {
  const { fetchData } = useHttp();

  let maximumAttachmentLimit = fileList?.length;
  let maximumAttachmentSizeInBytes = fileList?.reduce((total, curr) => {
    if (curr.status === "done") {
      return total + curr.size;
    } else {
      return total;
    }
  }, 0);

  const downloadAFile = async (key, sampleFileName) => {
    try {
      const response = await fetchData(`resource/download?key=${key}`, "GET", null, null, null, null);
      const url = JSON.parse(response)?.data;
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = sampleFileName;
      anchor.click();
      anchor.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      failureNotification(null, "Error downloading the file");
    }
  };

  const getSupportedAttachmentTypeString = (appProperties) => {
    const supportedAttachmentTypes = appProperties?.providerCommonObj?.supportedAttachmentTypes;
    let finalSupportedAttachmentTypes = "";

    if (supportedAttachmentTypes?.image) {
      finalSupportedAttachmentTypes += supportedAttachmentTypes?.image + ",";
    }

    if (supportedAttachmentTypes?.audio) {
      finalSupportedAttachmentTypes += supportedAttachmentTypes?.audio + ",";
    }

    if (supportedAttachmentTypes?.file) {
      finalSupportedAttachmentTypes += supportedAttachmentTypes?.file + ",";
    }
    if (supportedAttachmentTypes?.video) {
      finalSupportedAttachmentTypes += supportedAttachmentTypes?.video + ",";
    }

    return finalSupportedAttachmentTypes;
  };

  const onError = (uid, setUrl, errorMessage = "Failed to upload the file") => {
    if (setUrl) {
      setUrl("");
    }
    !alertNotNeeded && failureNotification("MMS Alert", errorMessage);
    setFileList(fileList.filter((file) => file.uid !== uid));
  };

  const updatePreview = (uploadedFile) => {
    setFileList([...(fileList?.filter((file) => file.status !== "error" && file.uid !== uploadedFile?.uid) || []), uploadedFile]);
  };

  const checkIsAttachmentLimitReached = () => {
    if (++maximumAttachmentLimit > appProperties.providerCommonObj?.maximumAttachmentLimit) {
      !alertNotNeeded && failureNotification("MMS Alert", "The MMS Attachment Limit has been exceeded");
      maximumAttachmentLimit--;
      return true;
    }
    return false;
  };

  const handleBeforeUpload = (file, url, setUrl, setIsInvalidUrl, props) => {
    if (checkIsAttachmentLimitReached()) {
      return false;
    }
    if (file) {
      maximumAttachmentSizeInBytes += file.size;
      if (appProperties?.providerCommonObj?.maximumAttachmentSizeInBytes < maximumAttachmentSizeInBytes) {
        maximumAttachmentSizeInBytes -= file.size;
        !alertNotNeeded && failureNotification("MMS Alert", `The MMS Attachment Size Limit has been reached`);
        return false;
      }
      return true;
    } else if (url) {
      if (httpsUrlRegex.test(url)) {
        return true;
      } else {
        setUrl("");
        setIsInvalidUrl(true);
        return false;
      }
    }
    return false;
  };
  const uploadAttachment = async (file, url, setUrl, setIsInvalidUrl, setVisible, integId, skipFilter, contactId) => {
    if (!handleBeforeUpload(file, url, setUrl, setIsInvalidUrl)) {
      return;
    }
    const randomUUID = crypto.randomUUID();
    let uploadedFile = {
      uid: randomUUID,
      status: "uploading",
    };

    updatePreview(uploadedFile);

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    } else if (url) {
      formData.append("url", url);
    }

    try {
      setVisible && setVisible(false);
      let response = await fetchData(
        `${integId}/attachment${skipFilter === "true" ? "?skipFilter=true" : ""}`,
        "POST",
        formData,
        appProperties,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      response = JSON.parse(response);
      if (response.status === 200 && response?.data && response?.data.message === "success") {
        const { name, size, url, thumbnailUrl, contentType } = response.data?.file;
        uploadedFile = {
          ...uploadedFile,
          name: name,
          status: "done",
          size: size,
          url: url,
          type: contentType,
          contentType: contentType,
          thumbnailUrl: thumbnailUrl,
          contactId: contactId,
        };
        setIsInvalidUrl && setIsInvalidUrl(false);

        updatePreview(uploadedFile);
        return true;
      } else {
        onError(uploadedFile.uid, setUrl, response?.data?.message);
        setIsInvalidUrl && setIsInvalidUrl(true);
        return false;
      }
    } catch (e) {
      console.error(e);
      onError(uploadedFile.uid, setUrl);
      return false;
    }
  };
  return { uploadAttachment, getSupportedAttachmentTypeString, downloadAFile };
}

export default useAttachment;
